@import '../../../theme/colors';

.headerProgram {
  flex: 1 1 auto;
  height: 300px;
  background-size: cover;
  background-position: center center;
  display: flex;
  margin-top: 15px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  &__blackFilter {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(40, 30, 48, 0.3), $greyRegular);
  }

  &__spacer {
    flex: 1 1 auto;
  }
  &__details {
    padding: 40px;
  }

  &__title {
    font-weight: 100;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    span {
      font-weight: bold;
    }
  }
  &__subtitle {
    text-align: center;
  }
  &__actionsProgram {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
