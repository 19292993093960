@import '../../../theme/colors';

.programcard {
  flex: 1;
  height: 340px;
  //max-width: 33%;
  min-width: 360px;
  background-size: cover;
  background-position: center;
  margin: 5px;
  border-radius: 6px;
  display: flex;
  transition: transform 0.2s;
  cursor: pointer;

  @media screen and (max-width: 1100px) {
    //max-width: 50%;
  }

  &__blackFilter {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(40, 30, 48, 0.3), $greyRegular);
    border-radius: 4px;
    padding: 10px;
  }
  &__blackFilter:hover {
    background: linear-gradient(rgba(40, 30, 48, 0.3), $greyLight);
  }

  &__spacer {
    flex: 1 1 auto;
  }
  &__details {
    padding: 20px 40px;
  }
  &__titletagempty {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    padding: 4px 10px;
    border-radius: 10px;
    background-color: rgba($greyRegular, 0.8);
    margin-right: auto;
    margin-left: auto;
    width: 90px;
    margin-bottom: 10px;
    opacity: 0;
  }
  &__titletag {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    padding: 4px 10px;
    border-radius: 10px;
    background-color: rgba($greyRegular, 0.8);
    margin-right: auto;
    margin-left: auto;
    width: 90px;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 100;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    span {
      font-weight: 700;
    }
  }
  &__subtitle {
    font-weight: 400;
    text-align: center;
    height: 40px;
    margin-top: 10px;
  }
  &__details {
    display: flex;
  }
  &__priceContainer {
    flex: 1 1 auto;
  }
  &__priceContainer2 {
    flex: 1 1 auto;
    text-align: right;
  }
  &__price {
    color: $primaryRegular;
    font-size: 24px;
    font-weight: 100;
  }
  &__lang {
    padding: 5px;
    img {
      width: 24px;
      height: 18px;
      margin-left: 5px;
      border-radius: 3px;
    }
  }
  &__priceTitle {
    font-size: 13px;
    font-weight: 300;
  }
  &__moreDetails {
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 1px;
    max-width: 200px;
    display: flex;
    svg {
      margin-right: 7px;
    }
  }
}
