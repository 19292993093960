@import '../../theme/colors';

.smallMentorCard {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  background-color: $greyLight;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  max-width: 400px;
  transition: all 0.4s ease;
  cursor: pointer;

  &__imgContainer {
    height: 60px;
    img {
      width: 40px;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
  &__textContainer {
    flex: 1 1 auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
  }
  &__actionContainer {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    :hover {
      background-color: $greyRegular;
    }
    a {
      text-decoration: none;
      color: $white;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  &__subtext {
    font-size: 12px;
    color: $greyLightLessOppacity;
    margin-top: 4px;
  }
}
.smallMentorCard:hover {
  background-color: $greyLightHover;
}
