@import '../../theme/colors';

.mobileDisplay {
  &__container {
    position: absolute;
    background-color: $greyRegular;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    a {
      color: $primaryRegular;
      font-size: 18px;
      line-height: 30px;
    }
  }
}
