@import '../../theme/colors';

.loader {
  &__container {
    position: fixed;
    background-color: $greyRegular;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1090;
    top: 0;
    left: 0;
    img {
      width: 60px;
      height: 60px;
    }
  }
  &__containermenu {
    top: 70px !important;
  }
}
