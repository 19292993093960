@import '../../theme/colors';

.onlineRouter {
  height: calc(100%-120px);
  position: 'relative';
  padding-top: 60px;
  padding-bottom: 60px;
}
body {
  overflow-y: scroll;
}

.popup-content {
  border: none !important;
  background-color: transparent !important;
  padding: 0;
  width: auto !important;
  @media screen and (max-width: 800px) {
  }
}
