@import '../../theme/colors';

.actionCard {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  background-color: $greyLight;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  max-width: 600px;

  &__textContainer {
    flex: 1 1 auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
  }
  &__actionTryed {
    color: $primaryRegular;
    display: flex;
    align-items: center;
    margin-top: 5px;
    text-align: right;
    justify-content: flex-end;
    svg {
      margin-right: 6px;
    }
  }
  &__actionContainer {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    :hover {
      background-color: $greyRegular;
    }
    a {
      text-decoration: none;
      color: $white;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  &__subtext {
    font-size: 14px;
    color: #ffffff90;
    margin-top: 4px;
  }
}
