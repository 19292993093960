@import '../../theme/colors';

.overlay {
  height: auto;
  width: 250px;
  position: absolute;
  z-index: 1080;
  top: 65px;
  right: 218px;
  @media screen and (min-width: 1200px) {
    right: calc(((100vw - (100vw - 100%) - 1200px) / 2) + 218px);
  }

  &__content {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: black;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    background-color: $greyLight;
    border-radius: 6px;
  }

  &__option {
    padding-bottom: 18px;
    padding-top: 18px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none !important;
    border-top: 1px solid $greyLighter !important;
    outline-offset: 0 !important;
    outline: 0 !important;
    span {
      margin-left: 10px;
    }
  }
  &__option:hover {
    text-decoration: none;
    background-color: $greyRegular;
  }
  &__logout {
    color: $error;

    border-bottom: 1px solid $greyLighter !important;
  }
}
.overlay:before {
  content: '\A';
  border-style: solid;
  border-width: 0 7px 10px 7px;
  border-color: transparent transparent $greyLight transparent;
  position: absolute;
  top: -10px;
  right: 18px;
}
