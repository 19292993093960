@import '../../../theme/colors';

.whiteIcon {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  span {
    flex: 1 1 auto;
    margin-left: 10px;
  }
}
