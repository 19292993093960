@import '../../../theme/colors';

.mentorPopup {
  background-color: $greyRegular;
  border-radius: 6px;
  max-width: 750px;
  min-height: 500px;
  display: flex;
  flex-direction: 'row';
  text-align: center;
  align-self: center;
  &__leftCol {
    max-width: 300px;
  }
  &__mentorCard {
    height: 100%;
    background-position: center center;
    background-size: cover;
    width: 300px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__mentorCardButton {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: rgba($greyRegular, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: rgba($greyRegular, 0.8);
    }
  }
  &__mentorCardSpacer {
    flex: 1 1 auto;
  }
  &__mentorCardBlackLayer {
    background: linear-gradient(transparent, rgba(40, 30, 48, 0.3), $greyRegular);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &__mentorCardText {
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    margin-top: 7px;
  }
  &__mentorCardSubtext {
    text-align: center;
    font-size: 16px;
    color: $greyLightLessOppacity;
  }
  &__mentorCardRightCol {
    flex: 1;
    padding: 15px;
  }
  &__mentorCardBio {
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
    margin-top: 7px;
    font-weight: 100;
  }
  &__mentorCardExpCardContainer {
  }
  &__mentorCardExpCard {
    background-color: $greyLight;
    border-radius: 6px;
    margin-top: 8px;
    padding: 5px;
  }
  &__mentorCardExpImg {
    border-radius: 5px;
    width: 40px;
    height: 40px;
  }
  &__mentorCardExpTitle {
    text-align: left;
    flex: 1;
    margin-left: 8px;
  }
  &__mentorCardExpSubTitle {
    text-align: left;
    margin-left: 8px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
}
.videoMentor-overlay {
  z-index: 1100 !important;
}
#mentorplayer {
  width: 750px !important;
  height: 500px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
