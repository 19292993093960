@import '../../theme/colors';

.separator {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  &__line {
    border-bottom: 1px solid $white;
    flex: 1;
    height: 0;
  }
  &__text {
    font-size: 16px;
    padding: 0px 8px;
    font-weight: 100;
    padding-bottom: 3px;
  }
}
