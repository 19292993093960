@import '../../theme/colors';

.offlineLayout {
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  img {
    width: 200px;
    margin-bottom: 20px;
  }
  &__container {
    background-color: $greyRegularDarker;
    display: flex;
    align-self: center;
    border-radius: 10px;
    padding: 20px;
  }
  &__containerLang {
    display: flex;
    margin-top: 15px;
  }
}
