@import '../../../theme/colors';

.comboDisplayCheckout {
  &__image {
    flex: 1;
    height: 150px;
    min-width: 400px;
    background-size: cover;
    background-position: center;
    margin: 5px;
    border-radius: 6px;
    display: flex;
    transition: transform 0.2s;
    margin-bottom: 15px;
    @media screen and (max-width: 500px) {
      min-width: 0;
    }
  }
  &__blackFilter {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(40, 30, 48, 0.5), rgba($greyRegular, 0.8), $greyRegular);
    border-radius: 5px;
    padding: 10px;
  }

  &__spacer {
    flex: 1 1 auto;
  }
  &__details {
    padding: 40px;
  }

  &__titletag {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    padding: 4px 10px;
    border-radius: 10px;
    background-color: rgba($greyRegular, 0.8);
    margin-right: auto;
    margin-left: auto;
    width: 90px;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 100;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    span {
      font-weight: 700;
    }
  }
  &__subtitle {
    font-weight: 400;
    text-align: center;
  }
  &__details {
    display: flex;
  }
  &__priceContainer {
    flex: 1 1 auto;
    margin-left: 8px;
  }
  &__price {
    color: $primaryRegular;
    font-size: 24px;
    font-weight: 100;
  }
  &__priceTitle {
    font-size: 13px;
    font-weight: 300;
  }
  &__moreDetails {
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 1px;
    max-width: 200px;
    display: flex;
    svg {
      margin-right: 7px;
    }
  }
  &__comboTitle {
    margin-bottom: 4px;
    font-size: 18px;
  }
}
