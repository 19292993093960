@import '../../../theme/colors';

.signin {
  &__link {
    display: block;
    margin-top: 10px;
    color: $white;
    text-decoration: none;
    font-size: 14px;
    a {
      text-decoration: none;
      color: $primaryRegular;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
