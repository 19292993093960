@import '../../theme/colors';

.pictoBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s ease;
  &:hover {
    background-color: $greyLightHover;
  }
}
