@import '../../../theme/colors';

.episodes {
  position: relative;
  &__videoContainer {
    display: flex;
    height: auto;
    top: 60px;
    width: 100%;
    max-width: 1200px;
    background-color: black;
    min-height: 433px;
    @media screen and (max-width: 1200px) {
      min-height: 0;
    }
  }

  &__descriptionContainer {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;

      align-items: center;
    }
  }
  &__descriptionRightContainer {
    flex: 1 1 auto;
    padding: 10px;
    max-width: 800px;
  }
  &__descriptionLeftContainer {
    min-width: 400px;
    padding: 5px;
    @media screen and (max-width: 800px) {
      width: auto;
      flex: 1 1 auto;
    }
  }

  &__textEpisodeNumber {
    font-weight: 100;
    margin-top: 15px;
  }

  &__textEpisodeTitle {
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
  }

  &__textEpisodeDescription {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    text-align: justify;
    text-justify: inter-word;
  }
  &__memo {
    width: 400px;
    height: 400px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: $greyRegular;
      transform: rotate(-5deg);
      font-size: 24px;
      padding: 35px;
      text-align: center;
      font-family: 'postit';
      z-index: 0;
    }
  }
  &__popup {
    background-color: $greyRegular;
    border-radius: 6px;
    max-width: 600px;
    text-align: center;
    align-self: center;
    display: flex;
  }
  &__popupContainer {
    align-items: 'center';
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  &__popupContainerRater {
    padding: 30px;
  }
  &__popupContainerTop {
    align-items: 'center';
    padding: 40px;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  &__popupContainerBottom {
    align-items: 'center';
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: left;
    background-color: $greyRegularDarker;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  &__popupContainerBottomLink {
    display: flex;
    align-items: center;
    padding: 5px;
    text-align: center;
    svg {
      margin-right: 10px;
      margin-left: 10px;
    }
    width: 45%;
  }
  &__popupContainerBottomLinkEnd {
    justify-content: flex-end;
    text-align: right;
  }
  &__popupTitle {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 40px;
  }
  &__popupSubTitle {
    font-size: 14px;
    line-height: 17px;
    width: 330px;
    text-align: center;
  }
  &__popupNextLContainer {
    display: flex;
    flex-direction: 'row';
    padding: 10px;
    border-radius: 5px;
    background-color: $greyLight;
    transition: 0.5s;
    margin-bottom: 10px;
    cursor: pointer;
    height: 80px;
    &:hover {
      background-color: $greyLightHover;
      transform: scale(1.01);
    }
  }

  &__popupNextLContainerEp {
    height: auto;
  }
  &__popupNextLImg {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__popupNextLText {
    flex: 1;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__popupNextLTextTitle {
    font-size: 18px;
    text-align: left;
  }
  &__popupNextLTextSubTitle {
    font-size: 15px;
    font-weight: 100;
    text-align: left;
  }
  &__popupNextLBtn {
    width: 50px;
  }
  &__popupNextLContainerTitle {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: bold;
  }
  &__ressourcesHL {
    border: 2px solid $primaryRegular;
    border-radius: 5px;
    transition: transform 0.5s ease;
    transform: scale(1.06);
  }
}
