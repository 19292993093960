body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato';
  background-color: #281e30;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  color: #fff;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
}
div#root {
  height: 100%;
  position: relative;
}
html {
  height: 100%;
  position: relative;
}
@font-face {
  font-family: 'postit';
  src: url(./assets/fonts/Noteworthy-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Light.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Heavy.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
