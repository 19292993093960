@import '../../../../theme/colors';

.rater {
  &__starContainer {
    display: flex;
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
    width: 350px;
  }
  &__legend {
    display: flex;
    width: 100%;
    margin-top: 15px;
  }
  &__legendText {
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    flex: 1 1 auto;
    text-align: center;
  }
  &__oneStarContainer {
    flex: 1 1 auto;
    text-align: center;
  }
  &__oneStarImgContainer {
    cursor: pointer;
  }
  &__oneStarImg {
    width: 40px;
    height: 40px;
  }
}
