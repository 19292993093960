@import '../../theme/colors';

.smallImageButtonCard {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: $greyLight;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  max-width: 400px;

  &__topContainer {
    display: flex;
    margin-bottom: 0;
    height: 75px;
  }
  &__imgContainer {
    height: 60px;
    img {
      width: 52px;
      height: 75px;
      border-radius: 4px;
      object-fit: cover;
    }
  }
  &__textContainer {
    flex: 1 1 auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
  }
  &__actionContainer {
    width: 100%;
    display: flex;
    a {
      text-decoration: none;
      color: $white;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      width: 100%;
      svg {
        margin-right: 6px;
      }
    }
  }
  &__subtext {
    font-size: 13px;
    color: rgba($white, 0.5);
    margin-top: 4px;
  }
}
.smallImageButtonCardHL {
}
