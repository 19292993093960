@import '../../../theme/colors';

.homev3 {
  height: calc(100% - 70px);
  position: relative;
  margin-top: 50px;

  &__titlecontainer {
    font-weight: 100;
    font-size: 32px;
    color: $primaryRegular;
    margin: 10px 0 5px 0;
  }
  &__cardcontainer {
    display: flex;
    width: calc(100%-20px);
    height: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
