@import '../../../theme/colors';

.programDetails {
  &__descriptionContainer {
    display: flex;
    margin-top: 20px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__description {
    flex: 1 1 auto;
    padding: 15px;
    min-width: 400px;
  }
  &__descriptionText {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    white-space: pre-wrap;
    margin-top: 15px;
  }
  &__episodesList {
    width: 400px;
    min-width: 400px;
  }

  &__mentorsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__mentorCard {
    height: 200px;
    background-position: center center;
    background-size: cover;
    margin: 5px;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
  }
  &__mentorCardSpacer {
    flex: 1 1 auto;
  }
  &__mentorCardBlackLayer {
    background: linear-gradient(transparent, rgba(40, 30, 48, 0.3), $greyRegular);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    border-radius: 6px;
    transition: all 0.5s ease;
  }

  &__mentorCardBlackLayer:hover {
    background: linear-gradient(transparent, rgba(40, 30, 48, 0.3), $greyLight);
  }
  &__mentorCardText {
    text-align: center;
  }
  &__mentorCardSubtext {
    text-align: center;
    font-size: 12px;
    color: $greyLightLessOppacity;
  }
  &__languages {
    display: flex;
    flex-direction: row;
    img {
      width: 24px;
      height: 20px;
      border-radius: 3px;
    }
  }
  &__langselected {
    padding: 6px;
    background-color: $greyLight;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__langnotselected {
    padding: 6px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
