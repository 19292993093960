@import '../../../../theme/colors';

.episodesList {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 40px;
  &__spacer {
    flex: 1 1 auto;
  }
  &__details {
    padding: 40px;
  }

  &__title {
    font-weight: 100;
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    span {
      font-weight: 700;
    }
  }
  &__subtitle {
    font-weight: 400;
    text-align: center;
  }
  &__episodeCard {
    display: flex;
    background-color: $greyLight;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;

    transition: transform 0.2s;
  }
  &__episodeCardUnlocked:hover {
    background-color: $greyLightHover;
  }
  &__episodeCardImage {
    background-position: center center;
    background-size: cover;
    width: 80px;
    height: 60px;
    border-radius: 3px;
  }

  &__episodeCardImageCover {
    background-color: rgba($greyRegular, 0.7);
    border-radius: 3px;
    width: 80px;
    height: 60px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 10px;
      line-height: 12px;
      align-self: flex-end;
      padding: 3px;
    }
  }
  &__episodeCardBar {
    background-color: $primaryRegular;
    height: 3px;
    border-radius: 5px;
  }
  &__episodeCardTextContainer {
    flex: 1 1 auto;
    margin-left: 10px;
    span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__itemContainerContainer {
    width: 60px;
    display: flex;
    flex-direction: column;
  }
  &__itemContainer {
    display: flex;
  }
  &__itemContainerNumberContainer {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    text-align: center;
    align-self: center;
    span {
      line-height: 28px;
    }
  }
  &__itemContainerBar {
    flex: 1 1 auto;
    display: flex;
  }
  &__itemContainerBarBar {
    flex: 1 1 auto;
    border-right: 3px solid rgba($white, 0.3);
  }
  &__itemContainerBarFil {
    flex: 1 1 auto;
  }
}
