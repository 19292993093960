@import '../theme/colors';
.checkout {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 850px) {
    flex-direction: column-reverse;

    align-items: center;
  }
  &__leftCol {
    flex: 1;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
  }
  &__leftColContainer {
    background-color: $greyRegularDarker;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    width: fit-content;
  }
  &__middleCol {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 850px) {
      transform: rotate(-90deg);
      margin: 10px 0;
    }
  }
  &__paymentSumTitle {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    margin: 0 0 15px 0;
  }
  &__fcLogo {
    width: 200px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__rightCol {
    flex: 1;
  }
  &__rightColContainer {
    background-color: $greyRegularDarker;
    display: flex;
    align-self: center;
    border-radius: 10px;
    padding: 20px;

    width: fit-content;
  }
}
